import React, { Component } from 'react'
import Producer from '../components/Content/Producer/Producer'

export default class ProducerPage extends Component {
  render() {
    return (
      <Producer></Producer>
    )
  }
}
