import React, { Component } from 'react'
import Banner from '../components/Content/Banner/Banner'

export default class BannerPage extends Component {
  render() {
    return (
      <Banner></Banner>
    )
  }
}
