import React, { Component } from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import callApi from '../../../utils/apiCaller';
import { startLoading, doneLoading } from 'utils/loading'
import { uploadImage } from 'utils/upload';
import {config} from 'config';
import './Banner.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
let token;

class Banner extends Component {
  constructor(props) {
      super(props);
      this.state = {
      banners: []
      };
  }

  async componentDidMount() {
      token = localStorage.getItem('_auth');
      const res = await callApi(`banner`, 'GET', null, token);
      this.setState({
          banners: res.data
      })
  }

  submitPrimarySlide = (type) => {
      return async (e) => {
          e.preventDefault();
          if(e.target.files[0]) {
              startLoading();
              const url = await uploadImage(e.target.files[0]);
              doneLoading();
              const data = {url, type}
              const res = await callApi(`banner`, 'POST', data, token);
              this.setState({
                  banners: res.data
              })
          }
      }
  }

  handleDeleteSlide = (id) => {
    return () => {
      MySwal.fire({
        title: "Xóa hình ảnh?",
        text: "Bạn muốn xóa hình ảnh này",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "có, Xóa ngay!",
        cancelButtonText: "Không",
      }).then(async (result) => {
        if(result.value) {
          const newBanners = await callApi(`banner/${id}`, 'DELETE', null , token);
          if(newBanners.status === 200) {
            this.setState({
              banners: newBanners.data
            })
          }
          
        }
      });
    }
  }

  render() {
    const {banners} = this.state;

    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Slide hình ảnh</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active">Hình ảnh</li>
          </ul>
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
                {/* Form Elements */}
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex align-items-center">
                            <h3 className="h4">Slide chính</h3>
                        </div>
                        <div className="card-body">
                            <label htmlFor="primarySlide" className="btn btn-primary" >Thêm ảnh</label>
                            <input  
                                hidden
                                id="primarySlide" 
                                type="file" 
                                accept="image/x-png,image/gif,image/jpeg" 
                                onChange={this.submitPrimarySlide('primary')}
                            />
                        </div>
                        <div className="slide-primary-body">
                            {
                                banners.map((banner, index) => {
                                  if(banner.type === 'primary') {
                                    return (
                                      <div className="slide-primary-item" key={index}>
                                        <img src={`${config.api.url_BE}/${banner.url}`}></img>
                                        <div className="slide-delete" onClick={this.handleDeleteSlide(banner.id)}><i className="fa fa-trash" style={{ color: '#fff', fontSize:"24px" }}></i></div>
                                      </div>
                                    )
                                  } 
                                })
                            }
                            {/* <div></div> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                    <div className="card-header d-flex align-items-center">
                        <h3 className="h4">Slide phụ</h3>
                    </div>
                    <div className="card-body">
                      <label htmlFor="subSlide" className="btn btn-primary" >Thêm ảnh</label>
                      <input  
                          hidden
                          id="subSlide" 
                          type="file" 
                          accept="image/x-png,image/gif,image/jpeg" 
                          onChange={this.submitPrimarySlide('subSlide')}
                      />
                    </div>
                    <div className="slide-primary-body">
                      {
                        banners.map((banner, index) => {
                          if(banner.type === 'subSlide') {
                            return (
                              <div className="slide-primary-item" key={index}>
                                <img src={`${config.api.url_BE}/${banner.url}`}></img>
                                <div className="slide-delete" onClick={this.handleDeleteSlide(banner.id)}><i className="fa fa-trash" style={{ color: '#fff', fontSize:"24px" }}></i></div>
                              </div>
                            )
                          } 
                        })
                      }
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/} 
      </div>
    )
  }
}

export default Banner
