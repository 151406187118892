import React, { Component } from 'react'
import Role from '../components/Content/Role/Role'

export default class RolePage extends Component {
  render() {
    return (
      <Role></Role>
    )
  }
}
