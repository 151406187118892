import React, { Component } from 'react'
import Rating from '../components/Content/Rating/Rating'

export default class RatingPage extends Component {
  render() {
    return (
      <Rating></Rating>
    )
  }
}
