import React, { Component } from 'react'
import  Order  from '../components/Content/Order/Order'

export default class OrderPage extends Component {
  render() {
    return (
        <Order></Order>
    )
  }
}
