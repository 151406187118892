import React, { Component } from 'react'
import  User  from '../components/Content/User/User'

export default class UserPage extends Component {
  render() {
    return (
      <User></User>
    )
  }
}
