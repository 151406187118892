import React, { Component } from 'react'
import  Contact  from '../components/Content/Contact/Contact'

export default class ContactPage extends Component {
    render() {
        return (
         <Contact></Contact>
        )
    }
}
