import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter';
// Edit
import {Link} from 'react-router-dom'
//
import { connect } from 'react-redux'
import callApi from '../../../utils/apiCaller';
import { actAddOrderRequest, actGetOrderRequest, actEditOrderRequest } from '../../../redux/actions/order';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
let token;
let id;

class ActionOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      address: '',
      note: '',
      phone: '',
      provinceData: '01',
      stateData: '001',
      totalDiscountPrice: 0,
      totalPrice: 0,
      createAt: null,
      status: 'Unconfirm',
      isPaid: false,
      redirectToOrder: false,
      provinces: null,
      states: null,
      code: null,
      dataOrderDetails: []
    };
    id = this.props.id
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    const myProvinces = await callApi('provinces', 'GET', null);
    if (id) {
      const res = await callApi(`orders/${id}`, 'GET', null, token);
      const myStates = await callApi(`provinces/${res.data.codeProvince}/states`, 'GET', null);
      const resOrderDetails = await callApi(`orders/${id}/orderDetails`, 'GET', null, token);
      console.log(resOrderDetails.data.results);
      this.setState({
        provinces: myProvinces.data,
        states: myStates.data,
        fullName: res.data.fullname,
        address: res.data.house,
        totalDiscountPrice: res.data.totalDiscountPrice,
        totalPrice: res.data.totalPrice,
        // paypalCode: res.data.paypalCode,
        provinceData: res.data.codeProvince,
        stateData: res.data.codeState,
        note: res.data.note,
        phone: res.data.phone,
        status: res.data.status,
        isPaid: res.data.isPaid,
        code: res.data.id,
        createAt: res.data.createAt,
        // isPaymentOnline: res.data.isPaymentOnline,
        dataOrderDetails: resOrderDetails.data.results
      })
    } else {
      const myStates = await callApi('provinces/01/states', 'GET', null);
      this.setState({
        provinces: myProvinces.data,
        states: myStates.data
      })
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { dataOrderDetails, provinces, states, provinceData, stateData, fullName, address, note, code, phone, totalPrice, totalDiscountPrice, isPaid, status, createAt, redirectToOrder } = this.state;
    let addressProvince;
    if (provinces && provinces.length) {
      provinces.map((item) => {
        if (item.code === provinceData) {
          addressProvince = item.name;
          return addressProvince;
        }
        return { message: 'error' }
      }) //output name province
    }

    let addressState;
    if (states && states.length) {
      states.map((item) => {
        if (item.code === stateData) {
          addressState = item.name;
          return addressState;
        }
        return { message: 'error' }
      }) //output name state
    }

    const addressResult = {
      province: addressProvince,
      state: addressState,
      house: address,
      codeProvince: provinceData,
      codeState: stateData,
    }// output address

    const newFullName = (fullName === '') ? null : fullName
    const newAddress = address === '' ? null : addressResult
    const newPhone = phone === '' ? null : phone;
    const newNote = note === '' ? null : note;

    if (!id) {
      const newOrder = {
        fullName: newFullName,
        address: newAddress,
        note: newNote,
        phone: newPhone,
        isPaid,
        status
      }
      this.props.add_order(token, newOrder);
      this.setState({
        fullName: '',
        address: '',
        note: '',
        phone: '',
        itemAmount: 0,
        promoTotal: 0,
        shippingTotal: 0,
        totalAmount: 0,
        isPaid: false,
        isPaymentOnline: false,
        status: 'Unconfirm'
      })
    } else {
      const editOrder = {
        fullname: newFullName,
        province: addressResult.province,
        codeProvince: addressResult.codeProvince,
        codeState: addressResult.codeState,
        state: addressResult.state,
        house: addressResult.house,
        note: note,
        phone: phone,
        totalPrice: totalPrice,
        totalDiscountPrice: totalDiscountPrice,
        isPaid,
        status
      }
      await this.props.edit_order(token, id, editOrder);
      callApi(
        `orders/sendOrderDetail/${id}`,
        "POST",
        {},
        token
      );
      this.setState({
        redirectToOrder: true
      })
    }


  }

  sumTotal = (itemAmount, shippingTotal, promoTotal) => {
    const newitemAmount = itemAmount ? itemAmount : 0;
    const newShippingTotal = shippingTotal ? shippingTotal : 0;
    const newpPomoTotal = promoTotal ? promoTotal : 0;

    const result = parseInt(newitemAmount) + parseInt(newShippingTotal) - parseInt(newpPomoTotal);
    if (result < 0) {
      return toast.error('ERROR! Total amount can not < 0');
    }
    return result;
  }

  handleChangeSelectProvince = async (event) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const res = await callApi(`provinces/${value}/states`, 'GET', null)
    this.setState({
      states: res.data,
      provinceData: value,
      stateData: res.data[0].code
    })
  } //get value of province
  handleChangeSelectState = (event) => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      stateData: value
    })
  } // get value of state

  render() {
    const { dataOrderDetails, provinces, states, provinceData, stateData, fullName, address, note, code, phone, totalPrice, totalDiscountPrice, isPaid, status, createAt, redirectToOrder } = this.state;
    let orderDetailAmount = 0;
    if (dataOrderDetails.length > 0) {
      orderDetailAmount = dataOrderDetails.reduce((sum, item) => {
        return sum += item.quantity * item.price
      }, 0)
    }
    if (redirectToOrder) {
      return <Redirect to='/orders'></Redirect>
    }
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Chi tiết đơn hàng</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Trang chủ</a></li>
            <li className="breadcrumb-item active">Đơn hàng</li>
          </ul>
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Mô tả</h3>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)} >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên người nhận</label>
                        <div className="col-sm-9">
                          <input disabled type="text" name="fullName" value={fullName} onChange={this.handleChange} className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Thành phố</label>
                        <div className="col-sm-3">
                          <select
                            disabled
                            name="provinces" value={provinceData}
                            onChange={this.handleChangeSelectProvince}
                            className="form-control mb-3">
                            {
                              (provinces && provinces.length) ? provinces.map((province, index) => {
                                return (
                                  <option key={index} value={province.code}>{province.name}</option>
                                )
                              }
                              ) : null
                            }
                          </select>
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Quận, Huyện</label>
                        <div className="col-sm-3">
                          <select
                            disabled
                            name="state" value={stateData}
                            onChange={this.handleChangeSelectState}
                            className="form-control mb-3"
                          >
                            {
                              (states && states.length) ? states.map((state, index) => {
                                return (
                                  <option key={index} value={state.code}>{state.name}</option>
                                )
                              }
                              ) : null
                            }
                            </select>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Địa chỉ</label>
                        <div className="col-sm-3">
                          <input disabled name="address" value={address} onChange={this.handleChange} type="text" className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Số điện thoại</label>
                        <div className="col-sm-3">
                          <input disabled name="phone" value={phone} onChange={this.handleChange} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Ghi chú</label>
                        <div className="col-sm-9">
                          <textarea disabled name="note" value={note} onChange={this.handleChange} type="text" rows={3} className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      {
                        id ? <div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label" style={{paddingTop: 50}}>Sản phẩm</label>
                        <div className="col-sm-9">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th>STT</th>
                                    <th>Tên sản phẩm</th>
                                    <th>Số lượng</th>
                                    <th>Tổng giá</th>
                                    <th>Giá khuyến mãi</th>
                                    <th>Ghi chú</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dataOrderDetails && dataOrderDetails.length ? dataOrderDetails.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          {/* Edit */}
                                          <div>{item.nameProduct}</div>
                                          {
                                            item.product.categoryId_categories && 
                                            <div className="cart-categories">
                                              { 
                                                item.product.categoryId_categories.map((category, index) => {
                                                  if(index === item.product.categoryId_categories.length - 1) {
                                                    return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-category">{ category.nameCategory || ''}</Link>
                                                  }
                                                  return <Link to={`/categories/${category.id || ''}`} key={index} className="cart-category">{`${category.nameCategory}, ` || ''}</Link>
                                                })
                                              }
                                            </div>
                                          }
                                          {/* end */}
                                        </td>
                                        <td>{item.quantity}</td>
                                        <td>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'VND' }).format(item.totalPrice)}</td>
                                        <td>{new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'VND' }).format(item.totalPrice - item.totalDiscountPrice)}</td>
                                        <td>{item.note}</td>
                                      </tr>
                                    )
                                  }) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                          <div className="line" />
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Mã đơn hàng</label>
                            <div className="col-sm-3">
                              <input disabled value={code} type="text" disabled className="form-control" />
                            </div>
                            <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}} >Thời gian đặt</label>
                            <div className="col-sm-3">
                              <Moment format="DD/MM/YYYY">
                                {createAt}
                              </Moment>
                            </div>
                          </div>
                          <div className="line" />
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">Tổng đơn</label>
                            <div className="col-sm-3">
                              {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'VND' }).format(totalPrice)}
                            </div>
                            <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Giá khuyến mãi</label>
                            <div className="col-sm-3">
                              {new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'VND' }).format(totalPrice - totalDiscountPrice)}
                            </div>
                          </div>
                        </div>
                          :
                          <div>
                            {/* <div className="line" />
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">Item Amount</label>
                              <div className="col-sm-3">
                                <input name="itemAmount"  value={itemAmount} onChange={this.handleChange} type="number" className="form-control" />
                              </div>
                              <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Shipping Total</label>
                              <div className="col-sm-3">
                                <input name="shippingTotal" value={shippingTotal} onChange={this.handleChange} type="number" className="form-control" />
                              </div>
                            </div>
                            <div className="line" />
                            <div className="form-group row">
                              <label className="col-sm-3 form-control-label">Promo Total</label>
                              <div className="col-sm-3">
                                <input name="shippingTotal" value={promoTotal} onChange={this.handleChange} type="number" className="form-control" />
                              </div>
                              <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Total Amount</label>
                              <div className="col-sm-3">
                                <input name="totalAmount" value={totalAmount} onChange={this.handleChange} type="number" className="form-control" />
                              </div>
                            </div> */}
                          </div>
                      }
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Trạng thái</label>
                        <div className="col-sm-9">
                          <select name="account" className="form-control mb-3" name="status" value={status} onChange={this.handleChange}>
                            <option value='Unconfirm'>Đang duyệt</option>
                            <option value='Confirm'>Đã duyệt</option>
                            <option value='Shipping' >Đang vận chuyển</option>
                            <option value='Complete' >Hoàn thành</option>
                            <option value='Canceled' >Đã hủy</option>
                          </select>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Thanh toán</label>
                        <div className="col-sm-3">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isPaid"
                              checked={isPaid}
                              className="checkbox-template" />
                          </div>
                        </div>
                        {/* <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Payment Online</label>
                        <div className="col-sm-3">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isPaymentOnline"
                              checked={isPaymentOnline}
                              className="checkbox-template" />
                          </div>
                        </div> */}
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button onClick={() => {this.setState({redirectToOrder: true})}} className="btn btn-secondary" style={{ marginRight: 2 }}>Hủy</button>
                          <button type="submit" className="btn btn-primary">Lưu lại</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_order: (token, newOrder) => {
      dispatch(actAddOrderRequest(token, newOrder))
    },
    get_order: (token, id) => {
      dispatch(actGetOrderRequest(token, id))
    },
    edit_order: (token, id, data) => {
      dispatch(actEditOrderRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionOrder)
