import React, { Component } from 'react'
import DashBoard from '../components/Content/DashBoard/DashBoard'

export default class DashBoardPage extends Component {
  render() {
    return (
      <DashBoard></DashBoard>
    )
  }
}
