// EDIT
import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actFetchProductsRequest, actDeleteProductRequest, actFindProductsRequest } from '../../../redux/actions/product';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MyFooter from '../../MyFooter/MyFooter'
import {exportExcel} from '../../../utils/exportExcel'
import {config} from '../../../config';
import  Paginator from 'react-js-paginator';
import { toast } from 'react-toastify';
import callApi from '../../../utils/apiCaller';
const MySwal = withReactContent(Swal)
let token;
class Product extends Component {

  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      currentPage: 1,
      searchText: '',
      category: '',
      dataCategories: []
    }
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.fetch_reload_data();
  }

  async fetch_reload_data(){
    token = localStorage.getItem('_auth');
    const resCategories = await callApi('categories?limit=100', 'GET', null, token);
    this.props.fetch_products(token).then(res => {
      this.setState({
        dataCategories: resCategories.data.results,
        total: res.total
      });
    }).catch(err => {
      console.log(err);  
    })
  }
  

  handlePageChange(content){
    const {category} = this.state;
    const limit = 10;
    const offset = limit * (content - 1);
    this.props.fetch_products(token, offset, category);
    this.setState({
      currentPage: content
    })
    window.scrollTo(0, 0);
  }

  handleRemove = (id) => {
    MySwal.fire({
      title: 'Bạn muốn xóa?',
      text: "Sản phẩm sẽ không thể khôi phục!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Đồng ý',
      cancelButtonText:'Hủy'
    }).then(async (result) => {
      if (result.value) {
        await this.props.delete_product(id, token);
        Swal.fire(
          'Đã xóa!',
          'Bạn đã xóa sản phẩm.',
          'success'
        )
        this.setState({
          total: this.state.total - 1 
        });
        // if(this.state.total === 0){

        // }
      }
    })
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleChangeCategory = (e) => {
    this.setState({
      category: e.target.value
    })
    const {currentPage} = this.state
    const limit = 10;
    const offset = limit * (currentPage - 1);
    this.props.fetch_products(token, offset, e.target.value).then(res => {
      this.setState({
        total: res.total
      });
    }).catch(err => {
      console.log(err);  
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { searchText } = this.state;
    if(!searchText) {
      return toast.error('Chưa nhập từ khóa tìm kiếm')
    }
    this.props.find_products(token, searchText).then(res => {
      this.setState({
        total: res.total
      })
    })
  }

  downloadExcel = () => {
    const key = 'products'
    exportExcel(key)
  }

  render() {
    let { products } = this.props;
    const { searchText, total, dataCategories, category } = this.state;

    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Sản phẩm</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
            <li className="breadcrumb-item active">Sản phẩm</li>
          </ul>
        </div>
        <section className="tables pt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Danh sách sản phẩm</h3>
                    {/*<button onClick={()=>this.downloadExcel()} style={{ border: 0, background: "white" }}> <i className="fa fa-file-excel-o"
                        style={{fontSize: 18, color: '#1d7044'}}> Excel</i></button>*/}
                    
                  </div>
                  <form onSubmit={(event) => this.handleSubmit(event)}
                    className="form-inline md-form form-sm mt-0" style={{ justifyContent: 'flex-end', paddingTop: 5, paddingRight: 20 }}>
                    {/* ///Edit */}
                    <div style={{marginLeft:"20px"}}>
                      <select name="account" className="form-control" name="category" value={category} onChange={this.handleChangeCategory}>
                        <option value=''>Tất cả</option>
                        {
                          dataCategories.map((category, index) => (
                            <option key={index} value={category.id || ''}>{category.nameCategory || ''}</option>
                          ))
                        }
                      </select>
                      <>   </>
                      <Link to='/products/add' className="btn btn-primary" > Tạo mới</Link>
                    </div>
                    <div style={{flexGrow: "1"}}></div>
                    {/* // */}
                    
                    <div>
                                        
                      <input
                        name="searchText"
                        onChange={this.handleChange}
                        value={searchText}
                        className="form-control form-control-sm ml-3 w-150" type="text" placeholder="Tìm kiếm sản phẩm theo tên"
                        aria-label="Search" />
                        <button style={{border: 0, background: 'white'}}> <i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                    {/*<Link to='/products/add' className="btn btn-primary" > Tạo mới</Link>*/}
                  </form>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>STT</th>
                            <th>Tên</th>
                            <th>Giá</th>
                            <th>Giá khuyễn mãi</th>
                            {/* <th>Properties</th> */}
                            <th style={{ textAlign: "center" }}>Hình ảnh</th>
                            <th style={{ textAlign: "center" }}>Trạng thái</th>
                            <th style={{ textAlign: "center" }}>Hành động</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products && products.length ? products.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{item.nameProduct}</td>
                                <td>{new Intl.NumberFormat('vn-VN', { style: 'currency', currency: 'VND' }).format(item.price)}</td>
                                <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.discountPrice)}</td>
                                {/* <td>{item.properties}</td> */}
                                <td style={{ textAlign: "center" }}>
                                  <div className="fix-cart">
                                    <img src={`${config.api.url_BE}/${item && item.image ? item.image : null}`} className="fix-img" alt="not found" />
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>{item.isActive ?
                                  <div className="i-checks">
                                    <input type="checkbox" checked={true} className="checkbox-template" />
                                  </div>
                                  :
                                  <div className="i-checks">
                                    <input type="checkbox" checked={false} className="checkbox-template" />
                                  </div>}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div>
                                    <span title='Edit' className="fix-action"><Link to={`/products/edit/${item.id}`}> <i className="fa fa-edit"></i></Link></span>
                                    <span title='Delete' onClick={() => this.handleRemove(item.id)} className="fix-action"><Link to="#"> <i className="fa fa-trash" style={{ color: '#ff00008f' }}></i></Link></span>
                                  </div>
                                </td>
                              </tr>
                            )
                          }) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <nav aria-label="Trang" style={{ float: "right" }}>
                  <ul className="pagination">
                   <Paginator
                        pageSize={10}
                        totalElements={total}
                        maxPagesToDisplay={5}
                        onPageChangeCallback={(e) => {this.handlePageChange(e)}}
                   />                 
                  </ul>
                 </nav>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_products: (token, offset, category) => {
       return dispatch(actFetchProductsRequest(token, offset, category))
    },
    delete_product: (id, token) => {
      dispatch(actDeleteProductRequest(id, token))
    },
    find_products: (token, searchText) => {
      return dispatch(actFindProductsRequest(token, searchText))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)