import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actFetchStaffsRequest, actDeleteUserRequest, actFindStaffsRequest } from '../../../redux/actions/user';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MyFooter from '../../MyFooter/MyFooter';
import Paginator from 'react-js-paginator';
import { toast } from 'react-toastify';
import {exportExcel} from '../../../utils/exportExcel'
const MySwal = withReactContent(Swal)

let token;
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      total: 0,
      currentPage: 1
    }
  }

  componentDidMount() {
   this.fetch_reload_data();
  }

  fetch_reload_data(){
    token = localStorage.getItem('_auth');
    this.props.fetch_staffs(token).then(res => {
      this.setState({
        total: res.total
      });
    }).catch(err => {
      console.log(err);  
    })
  }

  pageChange(content){
    const limit = 10;
    const offset = limit * (content - 1);
    this.props.fetch_users(token, offset);
    this.setState({
      currentPage: content
    })
    window.scrollTo(0, 0);
  }

  handleRemove = (id) => {
    MySwal.fire({
      title: 'Xóa tài khoản?',
      text: "Bạn có chắc chắn muốn xóa tài khoản này!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Có',
      cancelButtonText: 'Không',
    }).then(async (result) => {
      if (result.value) {
        await this.props.delete_user(id, token);
        Swal.fire(
          'Thành công!',
          'Đã xóa tài khoản thành công.',
          'success'
        )
      }
    })
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { searchText } = this.state;
    if(!searchText) {
      return toast.error('Chưa nhập từ khóa tìm kiếm')
    }
    this.props.find_staffs(token, searchText).then(res => {
      this.setState({
        total: res.total
      })
    })
  }

  downloadExcel = () => {
    const key = 'users'
    exportExcel(key)
  }

  render() {
    let { users } = this.props;
    const { searchText, total } = this.state;
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Phân quyền</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
            <li className="breadcrumb-item active">Phân quyền</li>
          </ul>
        </div>
        <section className="tables pt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Danh sách tài khoản</h3>
                    {/* <button onClick={()=>this.downloadExcel()} style={{ border: 0, background: "white" }}> <i className="fa fa-file-excel-o"
                        style={{fontSize: 18, color: '#1d7044'}}> Excel</i></button> */}
                  </div>
                  <form  onSubmit={(event) => this.handleSubmit(event)}
                   className="form-inline md-form form-sm mt-0" style={{ justifyContent: 'flex-end', paddingTop: 5, paddingRight: 20 }}>
                    <div>
                    <button style={{border: 0, background: 'white'}}><i className="fa fa-search" aria-hidden="true"></i></button>
                      <input
                       name="searchText"
                       onChange={this.handleChange}
                       value={searchText}
                       className="form-control form-control-sm ml-3 w-75" type="text" placeholder="Search"
                        aria-label="Search" />
                    </div>
                    <Link to='/roles/add' className="btn btn-primary" > Tạo mới</Link>
                  </form>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>STT</th>
                            <th>Tên</th>
                            <th>Tài khoản</th>
                            <th>Quyền</th>
                            <th style={{textAlign: "center"}}>Hành động</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users && users.length ? users.map((item, index) => {
                            if(item.roleId !== 'user') {
                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item.name}</td>
                                  <td>{item.email}</td>
                                  <td>{item.roleId}</td>
                                  <td style={{textAlign: "center"}}>
                                    <div>
                                      <span title='Edit' className="fix-action"><Link to={`/roles/edit/${item.id}`}> <i className="fa fa-edit"></i></Link></span>
                                      <span title='Delete' onClick={() => this.handleRemove(item.id)} className="fix-action"><Link to="#"> <i className="fa fa-trash" style={{ color: '#ff00008f' }}></i></Link></span>
                                    </div>
                                  </td>
                                </tr>
                              )
                            } 
                          }) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <nav aria-label="Page navigation example" style={{ float: "right" }}>
                  <ul className="pagination">
                  <Paginator
                        pageSize={25}
                        totalElements={total}
                        onPageChangeCallback={(e) => {this.pageChange(e)}}
                      />
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetch_staffs: (token, offset) => {
      return dispatch(actFetchStaffsRequest(token, offset))
    },
    delete_user: (id, token) => {
      dispatch(actDeleteUserRequest(id, token))
    },
    find_staffs: (token, searchText) => {
      return dispatch(actFindStaffsRequest(token, searchText))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User)