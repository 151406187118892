import React, { Component } from 'react'
import ActionDiscount from '../components/Content/Discount/ActionDiscount'

export default class ActionDiscountPage extends Component {
  render() {
    return (
      <ActionDiscount></ActionDiscount>
    )
  }
}
