import React, { Component } from 'react'
import  Categgory  from '../components/Content/Category/Category'

export default class CategoryPage extends Component {
  render() {
    return (
      <Categgory></Categgory>
    )
  }
}
