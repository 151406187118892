import React, { Component } from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import callApi from '../utils/apiCaller';
let token;

class ActionCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      email: '',
      passwordEmail: '',
      telegramId: '',
      telegramToken: '',
      minPrice: 0
    };
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    const res = await callApi(`setting`, 'GET', null, token);
    this.setState(res.data)
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const res = await callApi(`setting/${this.state.id}`, 'PUT', this.state, token);
    if(res.status === 200) {
      toast.success(res.data)
    }
  }

  render() {
    const {email, passwordEmail, telegramId, telegramToken, minPrice} = this.state;

    return (
      <div className="content-inner">
        {/* Page Header*/}
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Thiết lập thông tin</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="/">Trang chủ</a></li>
            <li className="breadcrumb-item active">Thiết lập</li>
          </ul>
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Mô tả</h3>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" onSubmit={this.handleSubmit} >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giá hạn mức</label>
                        <div className="col-sm-9">
                          <input name="minPrice" onChange={this.handleChange} value={minPrice} type="number" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Email</label>
                        <div className="col-sm-9">
                          <input name="email" onChange={this.handleChange} value={email} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Mật khẩu email</label>
                        <div className="col-sm-9">
                          <input name="passwordEmail" onChange={this.handleChange} value={passwordEmail} type="password" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Telegram id</label>
                        <div className="col-sm-9">
                          <input name="telegramId" onChange={this.handleChange} value={telegramId} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Telegram token</label>
                        <div className="col-sm-9">
                          <input name="telegramToken" onChange={this.handleChange} value={telegramToken} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button type="submit" className="btn btn-primary">Lưu lại</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/} 
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}
export default connect(null, mapDispatchToProps)(ActionCategory)
