import React, { Component } from 'react'
import  Discount  from '../components/Content/Discount/Discount'

export default class DiscountPage extends Component {
  render() {
    return (
     <Discount></Discount>
    )
  }
}
